import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "./App.css";
import Meme0 from './images/meme0.gif';
import Meme1 from './images/meme1.gif';
import Meme2 from './images/meme2.gif';
import Meme3 from './images/meme3.gif';
import Meme4 from './images/meme4.gif';

export const App = () => {

    const [aleatorio, setAleatorio] = useState<number>(0);
    const cantMemes: number = 5;

    const generarAleatorio = () => {
        const random = Math.floor(Math.random() * 100) % cantMemes;
        setAleatorio(random);
    }

    useEffect(() => {
        generarAleatorio();
    }, []);

    return (
        <div className="App">
            <Grid container justifyContent="center" alignItems="center" >
                {(() => {
                    switch(aleatorio){
                        case 0:     return (<img src={Meme0} title="meme0" alt="meme0" />)
                        case 1:     return (<img src={Meme1} title="meme1" alt="meme1" />)
                        case 2:     return (<img src={Meme2} title="meme2" alt="meme2" />)
                        case 3:     return (<img src={Meme3} title="meme3" alt="meme3" />)
                        case 4:     return (<img src={Meme4} title="meme4" alt="meme4" />)
                        default:    return (<img src={Meme0} title="meme0" alt="meme0" />)
                    }
                })()}
            </Grid>
        </div>
    );
};
